import { Children } from "react"


export default function TwoColumnSection({children}){

    const firstChild = Children.toArray(children)[0];
    const secondChild = Children.toArray(children)[1];

    return (
        <div className="flex w-full gap-24 flex-col md:flex-row">
            {firstChild}
            {secondChild}
        </div>
    )

}