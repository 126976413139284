import { useRef, useEffect, useState } from "react"



export default function TooltipImage({img, tooltip}){

    const tooltipRef = useRef(null)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [xPos, setXPos] = useState(0);
    
    useEffect(()=>{

        const resize = () => {
            setScreenWidth(window.innerWidth)
        }

        
        window.addEventListener('resize', resize)

        return () => window.removeEventListener('resize', resize);

    }, [])
    
    useEffect(()=>{
        const coordinates = tooltipRef.current.getBoundingClientRect();
        if(coordinates.x + coordinates.width > screenWidth){
            const roundedNumber = -(coordinates.x + coordinates.width - screenWidth + 5);
            setXPos(roundedNumber);
        }
        else{
            setXPos(0)

        }
    },[screenWidth])

    return(
        <div className="group relative">
            <span ref={tooltipRef} className={`absolute w-60 bg-slate-800/50 text-white rounded-lg backdrop-blur-md p-3 -top-44 invisible group-hover:visible`} style={{left: `${xPos}px`}}>{tooltip}</span>
            <img src={img} className="w-24 h-24 sm:w-16 sm:h-16 transition duration-300 ease-in-out transform hover:-translate-y-2 hover:scale-110" alt="react logo" />
        </div>
        
    )
}