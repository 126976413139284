import { useRef, useState, useEffect } from "react";

export default function AnimatedImage({src, alt, classData, delay}){

    const ref = useRef(null);

    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(()=>{

        const observer = new IntersectionObserver(([entry]) => {
            setIntersecting(entry.isIntersecting)
        },{
            
            rootMargin: '0px 0px -200px 0px', 
            threshold: 0.1 
        } 
        );
        
        observer.observe(ref.current);
        return () => {
          observer.disconnect();}

    }, [ref])


    return (
        <img ref={ref} src={src} alt={alt} className={`${classData} transition ease-in duration-1000 ${delay} ${isIntersecting ? "opacity-100 translate-x-0" : "opacity-0 translate-x-40"}`} />
    )
}