import { useEffect, useState} from "react";
import TwoColumnSection from "../components/TwoColumnSection";
import Wrapper from "../components/Wrapper";
import Nav from "../components/Nav";
import Button from "../components/Button";
import reactLogo from "../img/react-logo.svg";
import nodejsLogo from "../img/nodejs-logo.svg";
import powerbiLogo from "../img/powerbi-logo.svg";
import wordpressLogo from "../img/wordpress-logo.svg";
import powerappsLogo from "../img/powerapps-logo.svg";
import powerautomateLogo from "../img/powerautomate-logo.svg";
import androidLogo from "../img/android-logo.svg";
import iosLogo from "../img/ios-logo.svg";
import stopwatch from "../img/stopwatch.svg";
import lock from "../img/shield-lock.svg";
import graphup from "../img/graph-up.svg";
import transparent from "../img/transparency.svg";
import documented from "../img/card-checklist.svg";
import gear from "../img/gear.svg";
import TooltipImage from "../components/TooltipImage";
import Card from "../components/Card";
import Footer from "../components/Footer";
import websiteModel from "../img/websidemodel.png";
import mobileModel from "../img/mobilemodel.png";
import AnimatedImage from "../components/AnimatedImage";
import NumberCard from "../components/NumberCard";
import CallToAction from "../components/CallToAction";

export default function LandingPage() {

    const [textArray, setTextArray] = useState(['', '', '', '', '', '', '', '', '', '', '', '',
        '', '', '', '', '', '', '', '', '', '']);
    const [arrayCounter, setArrayCounter] = useState(0);
    const [wordCounter, setWordCounter] = useState(0);


    useEffect(() => {
        const wordArray = ['function ', 'buildSolution', '(){', 'gatherRequirements', '()', ';', 'plan', '()', ';', 'design', '()', ';',
            'develop', '()', ';', 'test', '()', ';', 'deliver', '()', ';', '}']
        let currentWord = wordArray[arrayCounter]

        const timeoutId = setTimeout(() => {
            if (currentWord && wordCounter < currentWord.length) {
                setTextArray((prev) => {
                    const newArray = [...prev];
                    newArray[arrayCounter] += currentWord[wordCounter];
                    return newArray;
                });
                setWordCounter(wordCounter + 1);
            }
            else {
                if (arrayCounter < wordArray.length) {
                    setArrayCounter(arrayCounter + 1);
                    setWordCounter(0);
                }
            }

        }, 50);

        return () => clearTimeout(timeoutId);

    }, [arrayCounter, wordCounter])

    return (
        <div className="bg-slate-900 p-4 isolate flex flex-col gap-16 overflow-hidden">
            <Nav />
            {/* <div className="absolute inset-0 m-auto max-w-xs h-[357px] blur-[118px] sm:max-w-md md:max-w-lg" style={{ background: "linear-gradient(106.89deg, rgba(192, 132, 252, 0.11) 15.73%, rgba(14, 165, 233, 0.41) 15.74%, rgba(232, 121, 249, 0.26) 56.49%, rgba(79, 70, 229, 0.4) 115.91%)" }}></div> */}
            <div className="absolute top-16 right-0 w-[calc(70%)] h-[calc(50%)] transform-gpu blur-3xl -z-10" aria-hidden="true">
                <div className="h-full w-full bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
                    style={{ clipPath: 'polygon(2% 23.4%, 30.4% 28.2%, 36% 6.2%, 44.8% 28.2%, 51.8% 8%, 60.2% 29.2%, 71.6% 9.8%, 76.8% 30.8%, 97.4% 26.8%, 84.2% 39.2%, 93.8% 64.2%, 75.4% 48.4%, 69.6% 64.4%, 60.2% 51.6%, 50.6% 67.2%, 41.2% 52.4%, 26.8% 65.2%, 25% 49%, 4.8% 61.8%, 27.2% 36.6%)' }}>
                </div>
            </div>
            <Wrapper>
                <TwoColumnSection>
                    <div className="flex flex-col gap-4 flex-grow justify-center basis-1">
                        <h1 className="text-4xl font-sans text-white font-extrabold">Solutii digitalizare si automatizare</h1>
                        <p className="text-white font-sans">Construim solutii pentru nevoile tale. Fie ca vorbim de website-uri, aplicatii desktop sau mobile, analiza de date ori solutii de automatizare, noi suntem oamenii potriviti pentru tine.</p>
                        <Button text="Contacteaza-ne" classes="mt-3 transition duration-500 ease-in-out bg-turquoise hover:bg-turquoise/50" handleClick={()=> window.location.href = 'mailto:contact@lexcode.ro'}/>
                    </div>
                    <div className="flex flex-grow basis-1">
                        <div className="bg-slate-800/50 rounded-lg backdrop-blur-3xl h-72 p-6 font-mono size-full shadow-sm">
                            <p className="text-lime-700 font-bold tracking-wider mb-3">//Dezvoltam ideile tale in cativa pasi simpli</p>
                            <p><span className="text-blue-300">{textArray[0]}</span><span className="text-yellow-200">{textArray[1]}</span><span className="text-yellow-400">{textArray[2]}</span></p>
                            <p><span className="text-yellow-200 ml-16">{textArray[3]}</span><span className="text-yellow-400">{textArray[4]}</span><span className="text-white">{textArray[5]}</span></p>
                            <p><span className="text-yellow-200 ml-16">{textArray[6]}</span><span className="text-yellow-400">{textArray[7]}</span><span className="text-white">{textArray[8]}</span></p>
                            <p><span className="text-yellow-200 ml-16">{textArray[9]}</span><span className="text-yellow-400">{textArray[10]}</span><span className="text-white">{textArray[11]}</span></p>
                            <p><span className="text-yellow-200 ml-16">{textArray[12]}</span><span className="text-yellow-400">{textArray[13]}</span><span className="text-white">{textArray[14]}</span></p>
                            <p><span className="text-yellow-200 ml-16">{textArray[15]}</span><span className="text-yellow-400">{textArray[16]}</span><span className="text-white">{textArray[17]}</span></p>
                            <p><span className="text-yellow-200 ml-16">{textArray[18]}</span><span className="text-yellow-400">{textArray[19]}</span><span className="text-white">{textArray[20]}</span></p>
                            <p><span className="text-yellow-400">{textArray[21]}</span></p>
                        </div>
                    </div>
                </TwoColumnSection>
                <div className="flex flex-col gap-6">
                    <h2 className="text-gray-500 text-2xl font-sans">Tehnologii</h2>
                    <div className="flex flex-wrap justify-around gap-5 sm:justify-between">
                        <TooltipImage img={reactLogo} tooltip={'Creeaza interfete web interactive si dinamice, optimizate pentru utilizatori, ideale pentru aplicatii care necesita experienta vizuala de top.'}/>
                        <TooltipImage img={nodejsLogo} tooltip={'Construieste backend-uri rapide si scalabile pentru aplicatii web complexe, care gestioneaza traficul si datele in timp real.'} />
                        <TooltipImage img={wordpressLogo} tooltip={'Lanseaza si administreaza site-uri web complet personalizabile, perfect pentru bloguri, magazine online si site-uri corporate.'} />
                        <TooltipImage img={powerbiLogo} tooltip={'Transforma datele tale in rapoarte vizuale usor de inteles, pentru a lua decizii mai bune si mai rapide.'}/>
                        <TooltipImage img={powerappsLogo} tooltip={'Creeaza aplicatii personalizate pentru a rezolva probleme specifice ale afacerii tale, fara a necesita programare avansata.'} />
                        <TooltipImage img={powerautomateLogo} tooltip={'Automatizeaza procese repetitive pentru a reduce costurile si a creste productivitatea afacerii tale.'} />
                        <TooltipImage img={androidLogo} tooltip={'Dezvolta aplicatii mobile performante si atragatoare pentru telefoanele si tabletele Android.'} />
                        <TooltipImage img={iosLogo} tooltip={'Construieste aplicatii rapide si intuitive pentru utilizatorii de iPhone si iPad, optimizate pentru ecosistemul Apple'} />
                    </div>
                </div>
                <div className="flex flex-col gap-10">
                    <div className="flex flex-col gap-2 sm:text-center">
                        <h3 className="text-turquoise text-md font-sans font-bold">Tot ce iti doresti</h3>
                        <h1 className="text-4xl font-sans text-white font-extrabold sm:text-3xl ">Cele mai bune servicii</h1>
                    </div>
                    <p className="text-white font-sans sm:text-center ">Oferim solutii complete pentru dezvoltarea de aplicatii, website-uri si solutii de automatizare. Garantam solutii fiabile si eficiente pe termen lung.</p>
                    <div className="hidden relative aspect-[16/6] sm:flex ">
                        <AnimatedImage src={websiteModel} alt="website model" classData={`absolute aspect-[16/10] w-[60%] left-[20%] shadow-lg rounded-3xl border-2 border-white`}/>
                        <AnimatedImage src={mobileModel} delay="delay-100" alt="mobile model" classData={`absolute w-[15%] aspect-[9/20] right-[17%] top-16 shadow-2xl rounded-3xl drop-shadow-2xl border-2 border-white `}/>
                    </div>
                    <div className="flex flex-wrap gap-10 justify-center">
                        <Card icon={stopwatch} alt='stopwatch' text='Ne angajam sa livram solutii personalizate intr-un timp cat mai scurt. Echipa noastra este dedicata sa raspunda prompt nevoilor tale.'/>
                        <Card icon={lock} alt='shield lock' delay="delay-200" text='Securitatea este o prioritate absoluta. Implementam cele mai eficiente masuri pentru a proteja datele si informatiile tale critice.' />
                        <Card icon={graphup} alt='graph' delay="delay-300" text='Solutiile noastre sunt optimizate pentru performanta maxima indiferent de natura lor.' />
                        <Card icon={transparent} alt='transparent' delay="delay-200" text='Credem in transparenta totala. Clientii nostri au acces complet la toate etapele procesului de dezvoltare.' />
                        <Card icon={documented} alt='document checklist' delay="delay-300" text='Fiecare pas al procesului nostru este documentat detaliat, oferindu-ti o imagine clara asupra dezvoltarii proiectului tau.' />
                        <Card icon={gear} alt='gear' delay="delay-500" text='Nu te lasam singur dupa finalizarea proiectului. Oferim suport continuu pentru a ne asigura ca solutia implementata functioneaza perfect.' />
                    </div>
                </div>
                <div className="flex flex-col gap-10">
                    <div className="flex flex-col gap-2">
                        <h1 className="text-4xl font-sans text-white font-extrabold sm:text-3xl">Expertiza</h1>
                    </div>
                    <p className="text-white font-sans md:w-[50%]">Investim constant în dezvoltarea profesională și tehnologică pentru a oferi cele mai bune rezultate. Datele noastre vorbesc de la sine:</p>
                    <div className="flex gap-8 flex-wrap mt-8 justify-between">
                        <NumberCard number={'15+'} text={'Certificari obtinute'}/>
                        <NumberCard number={'90%+'} text={'Customer satisfaction'}/>
                        <NumberCard number={'25%+'} text={'Costuri reduse pentru clienti'}/>
                        <NumberCard number={'100%'} text={'Rata raspuns'}/>
                    </div>
                </div>
                <CallToAction />
                <Footer />
            </Wrapper>


        </div>
    );

}