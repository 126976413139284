import logo64 from '../img/logo64.png'
import sal from "../img/anpc-sal.png";
import sol from "../img/anpc-sol.svg";

export default function Footer(){

    return(
        <div className="flex flex-col gap-10 mt-20">
            <div className="flex flex-wrap">
                <div className="flex flex-col gap-6"> 
                    <img src={logo64} className="h-6 w-6" alt="lexcode logo" />
                    <p className="text-white text-sm font-sans font-light italic">Transformam ideile tale in solutii digitale de succes.</p>
                    <div className="flex gap-2">
                    {/* Social Media icons here */}
                        <a target='_blank' rel='noreferrer' href='https://anpc.ro/ce-este-sal/'><img src={sal} alt='solutionarea alternativa a litigiilor' className="w-[105px] h-[25px]"/></a>
                        <a target='_blank' rel='noreferrer' href='https://ec.europa.eu/consumers/odr'><img src={sol} alt='solutionarea online a litigiilor' className="w-[105px] h-[25px]"/></a>
                    </div>
                </div>
            </div>
            <div>
                {/* Menu here */}
            </div>
            <div className='border-t-slate-400 border-t-[1px] border-solid py-4'>
                <p className="text-white text-xs font-sans font-thin">© 2024 Lexcode Solutions. All rights reserved.</p>
            </div>
        </div>
    )
}