import logo64 from '../img/logo64.png'

export default function Nav(){


    return (
        <div className='flex items-center gap-4 xl:mx-5'>
            <div className='flex gap-4 group cursor-pointer'>
                <img className='w-8 h-8 transition duration-500 ease-in-out opacity-100 group-hover:opacity-60' src={logo64} alt='lexcode-logo' />
                <div className='transition duration-500 ease-in-out opacity-100 group-hover:opacity-60'>
                    <span className='font-mono font-bold text-white text-lg'>lex</span><span className='font-mono font-bold text-turquoise text-lg' >code</span>
                </div>
            </div>
            
            
        </div>
    )
}