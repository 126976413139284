import Button from "./Button";
import {useRef, useState, useEffect} from 'react';

export default function CallToAction(){

    const ref = useRef(null);

    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(()=>{

        const observer = new IntersectionObserver(([entry]) => {
            setIntersecting(entry.isIntersecting)
        },{
            
            rootMargin: '0px 0px -200px 0px', 
            threshold: 0.1 
        } 
        );
        
        observer.observe(ref.current);
        return () => {
          observer.disconnect();}

    }, [ref])


    return(
        <div className="flex gap-7 flex-col items-center relative overflow-hidden py-10">
        <svg ref={ref} viewBox="0 0 1024 1024" aria-hidden="true" className={`absolute -z-10 w-64 blur-3xl top-[60%] md:w-96 transition-opacity ease-in duration-500 ${isIntersecting ? "opacity-70" : "opacity-0"}`}><circle r="512" cx="512" cy="512" fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7"></circle><defs><radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217"><stop stopColor="#4f46e5"></stop><stop offset="1" stopColor="#80caff"></stop></radialGradient></defs></svg>
            <div className="flex flex-col max-w-[600px] text-center gap-10">
                <h1 className="text-4xl font-sans text-white font-extrabold sm:text-3xl ">Gata de colaborare?</h1>
                <p className="text-white font-sans">Contacteaza-ne acum si hai sa discutam despre cum putem sa colaboram pentru a duce viziunea ta la urmatorul nivel.</p>
            </div>
            <Button text="Contacteaza-ne" classes="transition duration-500 ease-in-out bg-turquoise hover:bg-turquoise/50" handleClick={()=> window.location.href = 'mailto:contact@lexcode.ro'}/>

        </div>
    )
}