import { useRef, useEffect, useState } from "react";



export default function Card({icon, text, alt, delay}){

    const ref = useRef(null);

    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(()=>{

        const observer = new IntersectionObserver(([entry]) => {
            setIntersecting(entry.isIntersecting)
        },{
            
            rootMargin: '0px 0px -100px 0px', 
            threshold: 0.1 
        } 
        );
        
        observer.observe(ref.current);
        return () => {
          observer.disconnect();}

    }, [ref])

    return(
        <div ref={ref} className={`flex flex-grow-1 flex-shrink-1 gap-2 basis-[100%] transition-opacity ease-in duration-700 ${delay} ${isIntersecting ? "opacity-100" : "opacity-0"} sm:basis-[40%] md:basis-[30%]`}>
            <img src={icon} alt={alt} className="w-6 h-6"/>
            <div className="w-full">
                <p className="text-white font-sans">{text}</p>
            </div>
        </div>
    )

}